export interface ITranslatableText {
  [langCode: string]: string;
}

export interface IVisitProductConfig {
  ref: string;
  data: IVisitProductData;
}

export interface IVisitProductData {
  titlePosition: TVisitProductDataItemImagePosition;
  title: ITranslatableText;
  img: string;
  items: IVisitProductDataItem[];
}

export interface IVisitProductDataItem {
  type: VisitProductDataItemType;
  text?: ITranslatableText;
  video?: string;
  img?: string;
  imgPosition?: TVisitProductDataItemImagePosition;
  imgText?: ITranslatableText;
  textCredit?: ITranslatableText;
}

export type TVisitProductDataItemImagePosition = 'left' | 'right';

export enum VisitProductDataItemType {
  Intro = 'intro',
  Image = 'image',
  Quote = 'quote',
  Video = 'video',
}
