<div class="visit-product-overview-intro">
  <img loading="lazy" class="visit-product-overview-intro-img" [src]="getImageSrc(data.img)"/>
  <div class="visit-product-overview-intro-title visit-product-overview-text-style" [ngClass]="{'titleLeft': data.titlePosition === 'left'}">
    <div class="visit-product-overview-intro-title-p" [innerHTML]="data.title | translate"></div>
  </div>
</div>
<div class="visit-product-overview-content-container" *ngFor="let item of data.items">
  <div class="visit-product-overview-content-item-intro visit-product-overview-text-style"
       *ngIf="item.type === VisitProductDataItemType.Intro" [innerHTML]="item.text | translate">
  </div>
  <div class="visit-product-overview-content-item-intro visit-product-overview-text-style-quote-text"
       *ngIf="item.type === VisitProductDataItemType.Quote" [innerHTML]="item.text | translate">
  </div>
  <div class="visit-product-overview-content-item-intro visit-product-overview-text-style-quote-credits"
       *ngIf="item.type === VisitProductDataItemType.Quote" [innerHTML]="item.textCredit | translate">
  </div>
  <div class="visit-product-overview-content-item" *ngIf="item.type === VisitProductDataItemType.Video">
    <div class="visit-product-overview-content-item-text-div-big" *ngIf="item.videoPosition === 'right'" [ngClass]="{'visit-product-overview-content-item-text-visible': item.videoPosition === 'right'}">
      <div class="visit-product-overview-text-style visit-product-overview-content-item-text">
        <div class="visit-product-overview-content-item-text-container">
          <div class="visit-product-overview-content-item-text-title" [innerHTML]="item.videoTextTitle | translate"></div>
          <div class="visit-product-overview-content-item-text-content" [innerHTML]="item.videoTextContent | translate"></div>
        </div>
      </div>
    </div>
    <video *ngIf="item.type === VisitProductDataItemType.Video" class="visit-product-overview-content-item-image-big" [poster]="getImageSrc(item.poster)" playsinline onclick="this.controls = true"
      [ngClass]="{
      'visit-product-overview-content-item-image-left': item.videoPosition === 'left',
      'visit-product-overview-content-item-image-right': item.videoPosition === 'center'
      }">
      <source [src]="getVideoSrc(item.video)" type="video/mp4">
    </video>
    <video *ngIf="item.type === VisitProductDataItemType.Video" class="visit-product-overview-content-item-image-small" [poster]="getImageSrc(item.poster)" playsinline onclick="this.controls = true"
      [ngClass]="{
      'visit-product-overview-content-item-image-left': item.videoPosition === 'left',
      'visit-product-overview-content-item-image-right': item.videoPosition === 'center'
      }">
      <source [src]="getVideoSrc(item.video)" type="video/mp4">
    </video>
    <div class="visit-product-overview-content-item-text-div-big" *ngIf="item.videoPosition === 'left'" [ngClass]="{'visit-product-overview-content-item-text-visible': item.videoPosition === 'left'}">
      <div class="visit-product-overview-text-style visit-product-overview-content-item-text">
        <div class="visit-product-overview-content-item-text-container">
          <div class="visit-product-overview-content-item-text-title" [innerHTML]="item.videoTextTitle | translate"></div>
          <div class="visit-product-overview-content-item-text-content" [innerHTML]="item.videoTextContent | translate"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="visit-product-overview-content-item" *ngIf="item.type === VisitProductDataItemType.Image">
    <div class="visit-product-overview-content-item-text-div-big" *ngIf="item.imgPosition === 'right'" [ngClass]="{'visit-product-overview-content-item-text-visible': item.imgPosition === 'right'}">
      <div class="visit-product-overview-text-style visit-product-overview-content-item-text">
        <div class="visit-product-overview-content-item-text-container">
          <div class="visit-product-overview-content-item-text-title" [innerHTML]="item.imgTextTitle | translate"></div>
          <div class="visit-product-overview-content-item-text-content" [innerHTML]="item.imgTextContent | translate"></div>
        </div>
      </div>
    </div>
    <img loading="lazy" class="visit-product-overview-content-item-image-big" [src]="getImageSrc(item.img)"
      [ngClass]="{
      'visit-product-overview-content-item-image-left': item.imgPosition === 'left',
      'visit-product-overview-content-item-image-right': item.imgPosition === 'right'
      }"/>
    <img loading="lazy" class="visit-product-overview-content-item-image-small" [src]="getImageSrc(item.imgSmall)"
      [ngClass]="{
      'visit-product-overview-content-item-image-left': item.imgPosition === 'left',
        'visit-product-overview-content-item-image-right': item.imgPosition === 'right'
        }"/>
    <div class="visit-product-overview-content-item-text-div-big" *ngIf="item.imgPosition === 'left'" [ngClass]="{'visit-product-overview-content-item-text-visible': item.imgPosition === 'left'}">
      <div class="visit-product-overview-text-style visit-product-overview-content-item-text">
        <div class="visit-product-overview-content-item-text-container">
          <div class="visit-product-overview-content-item-text-title" [innerHTML]="item.imgTextTitle | translate"></div>
          <div class="visit-product-overview-content-item-text-content" [innerHTML]="item.imgTextContent | translate"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="visit-product-overview-content-item-text-div-small" *ngIf="item.type === VisitProductDataItemType.Image">
    <div class="visit-product-overview-text-style visit-product-overview-content-item-text">
      <div class="visit-product-overview-content-item-text-container">
        <div class="visit-product-overview-content-item-text-title" [innerHTML]="item.imgTextTitle | translate"></div>
        <div class="visit-product-overview-content-item-text-content" [innerHTML]="item.imgTextContent | translate"></div>
      </div>
    </div>
  </div>
</div>
